import React from "react";
import { StaticImage } from "gatsby-plugin-image";
const BannerCommonContent = ({ bannerContent, title, process }) => {
  return (
    <section className="lg:py-[120px] md:py-[100px] py-[80px] relative faq-des">
      {!process ? (
        <StaticImage
          placeholder="none"
          src="../images/guides-resources.webp"
          className="top-[-130px] !absolute right-0 z-[-1]"
          alt="guides-resources"
        />
      ) : (
        <StaticImage
          placeholder="none"
          src="../images/guides-resources-2.webp"
          className="top-[-200px] !absolute right-0 z-[-1] w-fit"
          alt="guides-resources"
        />
      )}
      <div className="container fade-in-animation">
        <h1
          className="first-comesup lg:text-[65px] relative md:text-[50px] text-[40px] leading-[1.2] tracking-[.08em] md:leading-[1.3684] mb-[18px] lg:mb-[20px] font-heading font-medium"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h1>
        <div className="second-comesup flex flex-col gap-[30px]">
          {bannerContent.map((item, index) => (
            <p
              className={`lg:text-[18px] md:text-[16px] text-[15px] tracking-[.03em] leading-[1.733] md:leading-[1.875] lg:leading-[1.67] font-body max-w-[668px] ${
                process ? "lg:max-w-[661px]" : "lg:max-w-[990px]"
              }`}
              key={index}
              dangerouslySetInnerHTML={{ __html: item }}
            ></p>
          ))}
        </div>
      </div>
    </section>
  );
};
export default BannerCommonContent;
