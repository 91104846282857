import React from 'react';
const CommonComponent = ({content}) => {
    return (
        <section className="lg:py-[120px] md:py-[100px] py-[80px] bg-[#FAFBFB] faq-des">
            <div className="container">
                <div className="flex flex-col lg:gap-[100px] md:gap-[80px] gap-[60px]">
                    {content.map((item, index) => (    
                        <div key={index}>
                            <h2 className="lg:text-[35px] md:text-[22px] text-[20px] tracking-[.08em] lg:font-medium font-medium lg:tracking-[.06em] font-heading leading-[1.3181] lg:leading-[1.5714] mb-[10px]"> {item.title}</h2>
                            <div className="flex flex-col md:gap-[20px] gap-[15px]">
                                {item.text.map((text, index) => (
                                    <p className="lg:text-[18px] md:text-[16px] text-[15px] tracking-[.03em] leading-[1.733] md:leading-[1.875] lg:leading-[1.67] font-body max-w-[668px] lg:max-w-[990px]" key={index}
                                        dangerouslySetInnerHTML={{ __html: text }}>
                                    </p>
                                ))}
                                {item.list && 
                                <ul className="common-list flex flex-col md:gap-[15px] gap-[12px]">
                                    {item.list?.listItem.map((list, index) => (
                                        <li key={index} className="lg:text-[18px] md:text-[16px] text-[15px] tracking-[.03em] leading-[1.733] md:leading-[1.875] lg:leading-[1.67] font-body max-w-[668px] lg:max-w-[990px] relative">{list}</li>
                                    ))}
                                    {item.list?.listText &&
                                    <p className="lg:text-[18px] md:text-[16px] ml-[-13px] text-[15px] tracking-[.03em] leading-[1.733] md:leading-[1.875] lg:leading-[1.67] font-body max-w-[668px] lg:max-w-[990px]">{item.list?.listText}</p>}
                                </ul>}
                                {item.text2?.map((text, index) => (
                                    <p className="lg:text-[18px] md:text-[16px] text-[15px] tracking-[.03em] leading-[1.733] md:leading-[1.875] lg:leading-[1.67] font-body max-w-[668px] lg:max-w-[990px]" key={index}
                                        dangerouslySetInnerHTML={{ __html: text }}>
                                    </p>
                                ))}
                                {item.list2 && 
                                <ul className="common-list flex flex-col md:gap-[15px] gap-[12px]">
                                    {item.list2?.listItem.map((list, index) => (
                                        <li key={index} className="lg:text-[18px] md:text-[16px] text-[15px] tracking-[.03em] leading-[1.733] md:leading-[1.875] lg:leading-[1.67] font-body max-w-[668px] lg:max-w-[990px] relative">{list}</li>
                                    ))}
                                    {item.list2?.listText && 
                                    <p className="lg:text-[18px] md:text-[16px] ml-[-13px] text-[15px] tracking-[.03em] leading-[1.733] md:leading-[1.875] lg:leading-[1.67] font-body max-w-[668px] lg:max-w-[990px]">{item.list2?.listText}</p>}
                                </ul>}
                                {item.text3?.map((text, index) => (
                                    <p className="lg:text-[18px] md:text-[16px] text-[15px] tracking-[.03em] leading-[1.733] md:leading-[1.875] lg:leading-[1.67] font-body max-w-[668px] lg:max-w-[990px]" key={index}
                                        dangerouslySetInnerHTML={{ __html: text }}>
                                    </p>
                                ))}
                                {item.list3 && 
                                <ul className="common-list flex flex-col md:gap-[15px] gap-[12px]">
                                    {item.list3?.listItem.map((list, index) => (
                                        <li key={index} className="lg:text-[18px] md:text-[16px] text-[15px] tracking-[.03em] leading-[1.733] md:leading-[1.875] lg:leading-[1.67] font-body max-w-[668px] lg:max-w-[990px] relative">{list}</li>
                                    ))}
                                    {item.list3?.listText &&
                                    <p className="lg:text-[18px] md:text-[16px] ml-[-13px] text-[15px] tracking-[.03em] leading-[1.733] md:leading-[1.875] lg:leading-[1.67] font-body max-w-[668px] lg:max-w-[990px]">{item.list2?.listText}</p>}
                                </ul>}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
export default CommonComponent
